import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var twoPoem = function twoPoem() {
  return import("./common/twoPoem");
};

var myFooter = function myFooter() {
  return import("./common/myFooter");
};

var treeHole = function treeHole() {
  return import("./common/treeHole");
};

var proPage = function proPage() {
  return import("./common/proPage");
};

var commentBox = function commentBox() {
  return import("./comment/commentBox");
};

export default {
  components: {
    twoPoem: twoPoem,
    myFooter: myFooter,
    treeHole: treeHole,
    proPage: proPage,
    commentBox: commentBox
  },
  data: function data() {
    return {
      treeHoleList: [],
      pagination: {
        current: 1,
        size: 10,
        total: 0
      },
      weiYanDialogVisible: false,
      isPublic: true,
      showFooter: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getWeiYan();
  },
  mounted: function mounted() {},
  methods: {
    toPage: function toPage(page) {
      this.pagination.current = page;
      window.scrollTo({
        top: 240,
        behavior: "smooth"
      });
      this.getWeiYan();
    },
    launch: function launch() {
      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      this.weiYanDialogVisible = true;
    },
    handleClose: function handleClose() {
      this.weiYanDialogVisible = false;
    },
    submitWeiYan: function submitWeiYan(content) {
      var _this = this;

      var weiYan = {
        content: content,
        isPublic: this.isPublic
      };
      this.$http.post(this.$constant.baseURL + "/weiYan/saveWeiYan", weiYan).then(function (res) {
        _this.getWeiYan();
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
      this.handleClose();
    },
    deleteTreeHole: function deleteTreeHole(id) {
      var _this2 = this;

      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this2.$http.get(_this2.$constant.baseURL + "/weiYan/deleteWeiYan", {
          id: id
        }).then(function (res) {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.pagination.current = 1;

          _this2.getWeiYan();
        }).catch(function (error) {
          _this2.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    },
    getWeiYan: function getWeiYan() {
      var _this3 = this;

      this.$http.post(this.$constant.baseURL + "/weiYan/listWeiYan", this.pagination).then(function (res) {
        _this3.showFooter = false;

        if (!_this3.$common.isEmpty(res.data)) {
          res.data.records.forEach(function (c) {
            c.content = c.content.replace(/\n{2,}/g, '<div style="height: 12px"></div>');
            c.content = c.content.replace(/\n/g, '<br/>');
            c.content = _this3.$common.faceReg(c.content);
            c.content = _this3.$common.pictureReg(c.content);
          });
          _this3.treeHoleList = res.data.records;
          _this3.pagination.total = res.data.total;
        }

        _this3.$nextTick(function () {
          _this3.showFooter = true;
        });
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};